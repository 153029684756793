import { CaseData, CaseStatus, ExtendedCaseData, FilterOptions, SortOptions } from "@planckdata/api"
import { CaseSortBy, SelectedFiltersValues } from "models/cases-filters"
import { AnswerCodeNames } from "./AnswerCodeNames"
import { BusinessClassification } from "./types"

const PARTIAL_WEB_PRESENCE_ANSWER_CODES: AnswerCodeNames[] = [
  AnswerCodeNames.low_web_presence,
  AnswerCodeNames.limited_web_presence,
  AnswerCodeNames.no_web_presence,
  AnswerCodeNames.no_match,
]

export function getWebPresence(caseData: CaseData | undefined | null): AnswerCodeNames {
  const answerCodes = caseData?.answer_codes.map((p) => p.code as AnswerCodeNames)
  const webPresenceAnswerCode = answerCodes?.find((code) => PARTIAL_WEB_PRESENCE_ANSWER_CODES.includes(code))
  if (webPresenceAnswerCode) {
    return webPresenceAnswerCode
  }

  return AnswerCodeNames.high_web_presence
}

export function createCasesRequest<T extends Partial<FilterOptions & SortOptions>>(
  request: T,
  filterString?: string,
  filters?: SelectedFiltersValues,
  sortBy?: CaseSortBy,
): T {
  request.business_name = filterString ? filterString : undefined

  if (sortBy) {
    request.sort_field = sortBy.field
    request.sort_direction = sortBy.order.toUpperCase() as any
  }

  if (filters) {
    const temp: any = request
    for (const f in filters) {
      if (filters[f] != null) {
        temp[f] = filters[f]
      }
    }
    request = temp
  }

  return request
}

type BaseMap = "roadmap" | "satellite" | "terrain"

export function buildGoogleMapUrl(latitude: number, longitude: number, zoom?: number, basemap?: BaseMap): string {
  const url = new URL("https://www.google.com/maps/@")
  url.searchParams.append("api", "1")
  url.searchParams.append("map_action", "map")
  url.searchParams.append("center", `${latitude},${longitude}`)
  if (zoom) {
    url.searchParams.append("zoom", zoom.toString())
  }
  if (basemap) {
    url.searchParams.append("basemap", basemap)
  }

  return url.toString()
}

export function filterVisibleClassifications(caseDataClassification: string[] | undefined): BusinessClassification[] {
  if (!caseDataClassification) {
    return []
  }

  return VISIBLE_CLASSIFICATIONS.filter((c) => caseDataClassification.includes(c))
}

export function getMainClassification(
  caseData: ExtendedCaseData | undefined,
  prioritizedClassifications: Array<BusinessClassification>,
): BusinessClassification | undefined {
  if (!caseData?.case?.classification) {
    return
  }

  return prioritizedClassifications.find((p) => caseData.case?.classification.includes(p))
}

export const VISIBLE_CLASSIFICATIONS = [
  BusinessClassification.CONSTRUCTION,
  BusinessClassification.MANUFACTURING,
  BusinessClassification.RBNT_JAPAN,
  BusinessClassification.RBNT,
  BusinessClassification.RETAIL,
  BusinessClassification.STAFFING,
]

export function getCaseDataStatus(caseData: CaseData): CaseStatus {
  if (caseData?.error) {
    return CaseStatus.ERROR
  }

  if (caseData?.is_completed) {
    return CaseStatus.COMPLETED
  }

  return CaseStatus.PROCESSING
}
